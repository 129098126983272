import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Message } from '../models/message.model';
import { State, toODataString } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';

export class ODataService extends BehaviorSubject<GridDataResult> {
  public gridDataResult: GridDataResult | undefined;
  public mapper: ((data: any[]) => any[]) | undefined;
  public loading = false;
  public state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  url: string;

  constructor(public http: HttpClient, url: string, mapper?: (data: any[]) => any[]) {
    super({ data: [], total: 0 });
    this.url = url;
    this.mapper = mapper;
  }

  public read(onComplete?: () => void) {
    this.loading = true; 
    return this.fetch()
      .pipe(
        tap((data) => {
          this.gridDataResult = data;
          this.loading = false;
        })
      )
      .subscribe((x) => {
        super.next(x);
        if (onComplete) {
          onComplete();
        }
      });
  }

  private fetch(): Observable<GridDataResult> {
    let fullUrl = '';
    if(!this.url.includes('?'))
      fullUrl = `${this.url}?${toODataString(this.state)}`;
    else
      fullUrl = `${this.url}&${toODataString(this.state)}`;
        
    return this.http.get<{ value: Message[]; '@odata.count': number }>(fullUrl).pipe(
      map((data) => {
        const mappedData = this.mapper ? this.mapper(data.value) : data.value;
        return {
          data: mappedData,
          total: data['@odata.count'],
        } as GridDataResult;
      })
    );
  }

  public onStateChange(state: State): void {
    this.state = state;
    this.read();
  }
}
